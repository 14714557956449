import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyBbazM1Ax-hclMcyc2wq8YYKY8lllG1qAY");

// set response language. Defaults to english.
Geocode.setLanguage("pt-BR");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("br");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export default async function RecoverCoordinates(address) {
  try {
    // Get latitude & longitude from address.
    const response = await Geocode.fromAddress(address);
    const { lat: latitude, lng: longitude } =
      response.results[0].geometry.location;
    return { latitude, longitude };
  } catch (error) {
    console.error(error);
    return { latitude: null, longitude: null };
  }
}
